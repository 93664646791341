#root {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

body {
    background: none;
    font-family: 'Noto Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

.ant-btn {
    background: none;
}
@import '@theme/index.less';

.app-header {
    background-color: @B1;
    height: 50px;
    padding: 0 38px;
    display: flex;
    justify-content: center;
    &-content {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }
    &-left {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &-logo {
            width: 93px;
            height: 30px;
            display: flex;
            align-items: center;
        }
        &-title {
            margin-left: 30px;
            font-family: PingFangSC-Medium, MicrosoftYaHei-Bold;
            font-size: 18px;
            font-weight: normal;
            letter-spacing: 0em;
            color: @w4;
        }
    }
    &-right {
        cursor: pointer;
        font-family: SourceHanSansCN-Medium, MicrosoftYaHei-Bold;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
        color: @w2;
    }
}

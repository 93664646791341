@import './color.less';

.cgtd-button {
    width: 112px;
    height: 38px;
    margin-left: 15px;
    display: flex;
    padding: 7px 38px;
    color: #6c6c6c;
    background: @B1;
    border-radius: 4px;
    box-sizing: border-box;
    border: 1px solid #cbd5e5;
    display: flex;
    justify-content: center;
    align-items: center;

    &-primary {
        margin-left: 15px !important;
        color: @B1;
        background-color: @primary-color;
        transition: all 0.3s;

        &:hover {
            color: #6c6c6c;
            background-color: @B1 !important;
        }
    }
}
@import '@theme/index.less';

.primary-layout {
    position: relative;
    width: 100%;
    // height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: flex-start;

    &-top {
        width: 100%;
        position: fixed;
        z-index: 2;
    }

    &-bottom {
        display: flex;
        margin-top: 50px;
        overflow: hidden;
        min-height: calc(100vh - 50px);

        &-left {
            background: #001529;
            width: 300px;
            position: fixed;
            overflow: auto;
            // height: calc(100% - 100px);
            height: calc(100% - 50px);
        }

        &-content {
            overflow: auto;
            background: none;
            margin-left: 300px;
        }
    }
}
@import '@theme/index.less';

.left-menu {
    // height: calc(100% + 78px);
    // min-height: 1000px;
    padding-top: 28px;

    &-item {
        font-family: SourceHanSansCN-Medium, MicrosoftYaHei-Bold;
        font-size: 16px;
        font-weight: 500;
        padding-left: 50px !important;
        height: 54px;
        letter-spacing: 0em;
        color: @w1;
    }

    &-submenu {
        &::before {
            content: '';
            display: block;
            width: 4px;
            height: 4px;
            background-color: @w1;
            border-radius: 50%;
            margin-right: 10px;
        }
    }

    .ant-menu-item-selected {
        background-color: @primary-color !important;
    }
}